@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

.crt-widget.crt-widget-branded .crt-logo {
    z-index: 100 !important;
}

.full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
}

:root {
    --main-color: #504751;
}

html {
   scroll-behavior: smooth; 
}

body {
    font-family: "Playfair Display", serif;
    overflow-x: hidden;
}

h2 {
    font-size: 2em !important;
    margin: 0.67em 0 !important;
}

.row,
.row--background,
.row--border {
    overflow-x: hidden;
}

.row--background {
    background-color: var(--main-color);
}

.row--border {
    border-top: 1px solid var(--main-color);
}

.width-50 {
    flex: 1;
    display: flex;
    justify-content: center;
}

.row__inner {
    display: flex;
    flex-wrap: wrap;
}

.navigation {
    position: sticky;
    top: 0;
    z-index: 200;
}

.navigation__bar {
    position: relative;
    height: 56px;
    background-color: #ffffff;
    border-bottom: 1px solid var(--main-color);
    display: flex;
    align-items: center;
    z-index: 210;
    padding: 0 16px;
    transition: border ease-in-out 0.2s;
}

.navigation__bar.isOpen {
    border-bottom: 0px;
}

.navigation__bar__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
}

.navigation__open {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: none;
    padding: 4px;
    transition: transform ease-in-out 0.2s;
}

.navigation__open.isOpen {
    transform: rotate(180deg);
}

.navigation__home {
    width: 40px;
    height: 40px;
    display: inline-block;
}

.navigation__home img {
    width: 100%;
    height: auto;
}

.navigation__content {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 209;
    transition: opacity ease-in-out 0.2s;
}

.navigation__content.hidden {
    opacity: 0;
    pointer-events: none;
}

.navigation__content__list {
    list-style: none;
    text-align: center;
    padding-left: 0;
}

.navigation__content__item a {
    color: black;
    text-decoration: none;
    font-size: 2rem;
    padding-bottom: 1rem;
    display: inline-block;
}

.navigation__content__item a:hover {
    text-decoration: underline;
}

.navigation__content__socials {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
}

.navigation__content__socials__entry {
    text-decoration: none;
    padding: 0 16px;
}

#header {
    width: 100%;
    height: 80vh;
    position: relative;
    overflow: hidden;
}

.header__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    z-index: 10;
    font-size: 3rem;
}

.header__backdrop {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#opening-hours {
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-width: 300px;
    text-align: center;
}

#find-us {
    padding: 32px 16px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-width: 300px;
    text-align: center;
}

.find-us__image {
    max-width: 100%;
    height: auto;
}

#about {
    padding: 32px 16px;
    color: #ffffff;
}

.about__wrapper {
    display: flex;
    flex-flow: column;
}

.about__title {
    position: relative;
}

.about__title::before {
    content: "OM";
    position: absolute;
    top: -18px;
    left: 0;
    font-size: 1.5rem;
}

.about__description {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 0;
}

.about__image {
    max-width: 300px;
    border-radius: 25px;
}

#feed {
    padding: 48px 0;
}

.feed__title {
    text-align: center;
    padding-bottom: 24px;
    margin: 0;
}

#handel {
    padding: 32px 16px;
    max-width: 800px;
    min-width: 300px;
    margin: 0 auto;
}

.handel__title {
    text-align: center;
}

#post {
    padding: 32px 16px;
    max-width: 800px;
    min-width: 300px;
    margin: 0 auto;
}

.post__title {
    text-align: center;
}

#post p {
    font-size: 1.2rem;
    line-height: 1.5;
}

#post img {
    max-width: 100%;
    height: auto;
}

#lampshades {
    padding: 32px 16px;
    color: #ffffff;
    flex: 1;
    min-width: 0;
}

.lampshades__wrapper {
    display: flex;
    flex-flow: column;
    padding-bottom: 16px;
}

.lampshades__title {
    position: relative;
}

.lampshades__description {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 0;
}

.lampshades__button {
    color: var(--main-color);
    max-width: 300px;
    background-color: #ffffff;
    border: none;
    padding: 16px 24px;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.lampshades__button:hover {
    background-color: #efefef;
}

.lampshades__img-container {
   scroll-behavior: smooth; 
    box-sizing: border-box;
    height: 400px;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0 16px;
}

.lampshades__img {
    height: 400px;
    width: auto;
    padding-right: 8px;
}

.lampshades__sizes__container {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 300;
}

.lampshades__sizes__container.isOpen {
    opacity: 1;
    pointer-events: initial;
}

.lampshades__sizes {
    background-color: #ffffff;
    width: 70%;
    max-height: 70%;
    padding: 40px 16px 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 310;
    color: black;
}

.lampshades__sizes svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
}

.lampshades__sizes__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 309;
}

.lampshades__img__back,
.lampshades__img__forward {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.lampshades__img__back {
    left: 16px;
}

.lampshades__img__forward {
    right: 16px;
}

#inspiration {
    width: 100%;
    padding: 32px 16px;
}

.inspirations__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.inspiration__entry {
    width: 100%;
    height: 200px;
    position: relative;
    display: block;
}

.inspiration__entry__title {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    font-size: 2rem;
    text-align: center;
}

.inspiration__entry__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.inspiration__entry__backdrop {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}

#contact {
    padding: 32px 16px;
    color: white;
}

#contact .contact__email path,
#contact .contact__ig path
{
    fill: white;
}

#contact .contact__phone path,
#contact .contact__fb path
{
    stroke: white;
}

.contact__wrapper {
    flex: 1;
    display: flex;
    flex-flow: column;
}

.contact__phone,
.contact__email {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}

.contact__phone svg,
.contact__email svg {
    padding-right: 8px;
}

.contact__phone:hover,
.contact__email:hover {
    text-decoration: underline;
}

.contact__socials {
    display: flex;
    flex-flow: column;
    padding-bottom: 16px;
}

.contact__socials__entry {
    padding-bottom: 8px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: white;
}

.contact__socials__entry svg {
    padding-right: 8px;
}

.contact__socials__entry:hover {
    text-decoration: underline;
}

.contact__text {
    padding-top: 16px;
}

@media (min-width: 768px) {
    .about__wrapper {
        flex-flow: row;
    }

    .about__description {
        max-width: 70%;
        padding-right: 32px;
    }

    .about__image {
        align-self: flex-start;
    }

    .navigation__bar {
        justify-content: center;
    }

    .navigation__bar__inner {
        max-width: 800px;
    }

    .header__title {
        font-size: 5rem;
    }

    .handel__title {
        text-align: left;
    }

    .post__title {
        text-align: left;
    }

    .lampshades__wrapper {
        flex-flow: row;
    }

    .lampshades__description {
        max-width: 70%;
        padding-right: 64px;
    }

    .lampshades__button {
        align-self: center;
    }

    .lampshades__sizes {
        width: unset;
        max-width: 70%;
        font-size: 1.2rem;
        line-height: 1.5;
    }

    .inspirations__wrapper {
        grid-template-columns: 1fr 1fr;
    }


    #contact {
        color: white;
        flex: 1;
        text-align: center;
    }

    #contact .contact__email path,
    #contact .contact__ig path
    {
        fill: white;
    }

    #contact .contact__phone path,
    #contact .contact__fb path
    {
        stroke: white;
    }

    .contact__description {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    .contact__phone,
    .contact__email {
        justify-content: center;
    }

    .contact__wrapper {
        flex-flow: row;
        justify-content: space-between;
    }

    .contact__socials {
        padding-bottom: 0;
    }

    .contact__text {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {
    #about {
        padding: 32px 0;
    }

    .row__inner {
        width: 1200px;
        margin: 0 auto;
    }

    .width-50 {
        max-width: 50%;
        display: flex;
        justify-content: center;
    }

    #lampshades {
        padding: 32px 0;
    }

    .lampshades__img-container {
        padding: 0 32px;
    }

    #inspiration {
        padding: 32px 0;
    }

    #contact {
        padding: 32px 0;
    }
}
